// import { RemixBrowser } from "@remix-run/react";
// import i18next from "i18next";
// // @ts-ignore
// import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from "i18next-http-backend";
// import { hydrate } from "react-dom";
// import { I18nextProvider, initReactI18next } from "react-i18next";
// import { getInitialNamespaces } from "remix-i18next";

// i18next
//   .use(initReactI18next) // Tell i18next to use the react-i18next plugin
//   .use(LanguageDetector) // Setup a client-side language detector
//   .use(Backend) // Setup your backend
//   .init({
//     // This is normal i18next config, except a few things
//     supportedLngs: ["es", "en"],
//     defaultNS: "common",
//     fallbackLng: "en",
//     // Disabling suspense is recommended
//     react: { useSuspense: false },
//     // This function detects the namespaces your routes rendered while SSR use
//     // and pass them here to load the translations
//     ns: getInitialNamespaces(),
//     backend: { loadPath: "/locales/{{lng}}/{{ns}}.json" },
//     detection: {
//       // Here only enable htmlTag detection, we'll detect the language only
//       // server-side with remix-i18next, by using the `<html lang>` attribute
//       // we can communicate to the client the language detected server-side
//       order: ["htmlTag"],
//       // Because we only use htmlTag, there's no reason to cache the language
//       // on the browser, so we disable it
//       caches: [],
//     },
//   })
//   .then(() => {
//     // After i18next has been initialized, we can hydrate the app
//     // We need to wait to ensure translations are loaded before the hydration
//     // Here wrap RemixBrowser in I18nextProvider from react-i18next
//     return hydrate(
//       <I18nextProvider i18n={i18next}>
//         <RemixBrowser />
//       </I18nextProvider>,
//       document
//     );
//   });
import { RemixBrowser } from "@remix-run/react";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";
import { i18nConfig } from "./utils/i18n";
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { getInitialNamespaces } from "remix-i18next/client";

async function hydrate() {
  await i18next
    .use(initReactI18next) // Tell i18next to use the react-i18next plugin
    .use(LanguageDetector) // Setup a client-side language detector
    .use(Backend) // Setup your backend
    .init({
      ...i18nConfig, // spread the configuration
      // This function detects the namespaces your routes rendered while SSR use
      ns: getInitialNamespaces(),
      backend: { loadPath: "/locales/{{lng}}/{{ns}}.json" },
      detection: {
        // Here only enable htmlTag detection, we'll detect the language only
        // server-side with remix-i18next, by using the `<html lang>` attribute
        // we can communicate to the client the language detected server-side
        order: ["htmlTag"],
        // Because we only use htmlTag, there's no reason to cache the language
        // on the browser, so we disable it
        caches: [],
      },
    });

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <StrictMode>
          <RemixBrowser />
        </StrictMode>
      </I18nextProvider>
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}

